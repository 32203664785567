import React, { useEffect, useState } from 'react';
import { ProfileStyled } from './styled';
import { Form, Input, Spin } from 'antd';
import { CopyOutlined, LoadingOutlined, MailOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../stores';
import Toast from '../../components/toast';
import { BG_COVER } from '../../theme/images';
import { getUserInfoAction } from '../../stores/screens/auth/auth.action';
import { oneDecimalPlace } from '../../utils/number';
import Misc from '../../utils/misc';
import Storage from '../../utils/storage';
import ButtonImage from '../../components/button-img';

import WithdrawModal from './WithdrawModal';

const Profile = () => {
  const dispatch = useAppDispatch();
  const { userInfo, isLoading } = useAppSelector((state) => state.auth);
  const [params, setParams] = useState('');
  const [showModal, setShowModal] = useState(false);

  const getInfo = () => {
    dispatch(getUserInfoAction({ accessToken: Storage.get('ACCESS_TOKEN') }));
  };

  useEffect(() => {
    if (userInfo) {
      const prm = btoa(
        JSON.stringify({
          accountEmail: userInfo?.email,
          referral: userInfo?.referral_code,
        })
      );
      setParams(prm);
    }
  }, [userInfo]);

  useEffect(() => {
    getInfo();
    /* eslint-disable */
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(userInfo?.wallet_address).then(() => {
      Toast.show('Copied to clipboard!');
    });
  };

  return (
    <ProfileStyled bgImage={BG_COVER}>
      {(isLoading) && (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
              }}
              spin
            />
          }
        />
      )}

      <div className="title">Profile</div>
      <div className="desktop-bg"></div>
      <div className="divider"></div>

      <div className="dp-flex">
        <div className="user">
          <div className="mobile-bg"></div>
          {userInfo?.avatar_url ? (
            <img
              className="avatar"
              width={48}
              height={48}
              src={userInfo?.avatar_url}
              alt="logo-user"
            />
          ) : (
            <div className="logo-default">
              <UserOutlined className="icon-user" />
            </div>
          )}
        </div>
        <Form className="form-profile">
          <Form.Item className='input-wallet'>
            <label>Wallet address</label>
            <Input
              addonBefore={<WalletOutlined width={20} height={20} />}
              value={Misc.trimPublicAddress(userInfo?.wallet_address || '', 8)}
              suffix={<CopyOutlined width={20} height={20} onClick={handleCopy} />}
              disabled
            />
          </Form.Item>
          <Form.Item className='input-email'>
            <label>Email</label>
            <Input
              addonBefore={<MailOutlined width={20} height={20} />}
              value={userInfo?.email || ''}
              disabled
            />
          </Form.Item>
          {userInfo && userInfo?.telegram_coins_balance !== null && (
            <Form.Item>
              <label>MTel Point</label>
              <Input
                value={oneDecimalPlace(userInfo?.telegram_coins_balance || 0)}
                disabled
              />
            </Form.Item>
          )}
          <div className="dp-flex-btn">
            <ButtonImage
              type="button"
              height={50}
              textTransform="unset"
              fontWeight={300}
              fontSize={14}
            >
              <a
                className="color-white"
                href={`${process.env.REACT_APP_TELEGRAM_SHORT_LINK}?startapp=${
                  userInfo?.linked ? '' : params
                }`}
                target="_blank"
              >
                {userInfo?.linked ? 'Play MCC Game' : 'Link to Telegram'}
              </a>
            </ButtonImage>
            {userInfo && userInfo?.linked && (
              <ButtonImage
                type="button"
                height={50}
                textTransform="unset"
                fontWeight={300}
                fontSize={12}
                onClick={() => setShowModal(true)}
              >
                Withdraw MTel Point to Wallet
              </ButtonImage>
            )} 
          </div>
        </Form>
      </div>
      {showModal && (
        <WithdrawModal
          isOpenModal={showModal}
          title="Withdraw MTel Point to Wallet"
          onCancel={() => {
            setShowModal(false);
          }}
          fee_percent={userInfo?.fee_percent || 0}
          mtelBalance={userInfo?.telegram_coins_balance || 0}
          getInfo={getInfo}
        />
      )}
    </ProfileStyled>
  );
};

export default Profile;
