import styled from 'styled-components';
import { Modal } from 'antd';

export const WithdrawModalStyled = styled(Modal)`
  background: linear-gradient(270deg, #052b86 0%, #03153f 100%);
  border-radius: 20px;
  width: 550px !important;
  .custom-preview {
    padding: 0px;
    .ant-modal-content {
      .ant-modal-body {
        padding: 0px;
      }
    }
  }
  .ant-modal-content,
  .ant-modal-header {
    color: #fff;
    background: transparent;
  }

  .ant-modal-title {
    color: #fff;
    font-size: 20px;
  }

  .ant-modal-header {
    border: unset;
    padding: 30px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .ant-modal-body {
    padding-top: 0px;
  }

  .btn-group {
    justify-content: flex-end;
    margin-top: 30px;
  }

  .required {
    color: red;
  }

  .error {
    color: red;
    font-size: 12px;
  }

  .text-12 {
    font-size: 12px;
  }
  .text-10 {
    font-size: 10px;
  }
  .small-text {
    font-size: 10px;
    color: #757b8c;

    div {
      white-space: nowrap;
    }
  }

  .dp-flex {
    display: flex;
    gap: 20px;
  }
  .ant-input {
    padding: 10px;
  }
  .ant-spin-spinning {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      content: '';
      background: #ececec;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0.2;
      border-radius: 20px;
    }
  }

  .ant-input[disabled] {
    background: rgba(186, 186, 186, 0.21);
    border-color: #156df2;
    color: #fff;
  }

  .custom {
    justify-content: center;
    align-items: center;
  }

  .flex-start {
    justify-content: flex-start;
  }

  input::placeholder,
  textarea::placeholder,
  .ant-select-selection-placeholder {
    color: #757b8c;
  }
`;
