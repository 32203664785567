import * as Yup from 'yup';

import { REGEX_URL } from '../../../constants';
const regexPhone = /^\d{9,11}$/;
export const schema =
  Yup.object().shape({
    companyName: Yup.string()
      .required('This field is required')
      .max(255, 'This field max length is 255')
      .trim(),
    URL: Yup.string()
      .required('This field is required')
      .matches(REGEX_URL, 'Enter a valid url')
      .max(255, 'This field max length is 255')
      // .test('', (value) => {})
      .trim(),
    title: Yup.string()
      .required('This field is required')
      .max(255, 'This field max length is 255')
      .trim(),
    introduction: Yup.string()
      .required('This field is required')
      .max(1000, 'This field max length is 1000')
      .trim(),
    country: Yup.string()
      .required('Please select a country'),
    phone: Yup.string()
      .required('This field is required')
      .transform((currentValue) => currentValue.replace(/-/g, ""))
      .trim()
      .matches(/^\d+$/, 'Phone number must contain only digits')
      .matches(regexPhone, 'Phone number must be a valid number with 9 to 11 digits')
      .test("phone", "Phone number must be a valid number", (phone) => {
        if (!phone) {
          return true;
        }
        return regexPhone.test(phone);
      }),
    type: Yup.string().required('This field is required'),
    files: Yup.mixed().required('This field is required'),
    aspect_ratio: Yup.string().required('This field is required'),
    thumbnail: Yup.mixed().when('type', {
      is: (val) => val && val.toLowerCase() === 'video',
      then: () => Yup.mixed().required('This field is required'),
      otherwise: () => Yup.mixed().nullable(),
    }),
  });
