export const handleAspectRatio = (aspectRatio) => {
  switch (aspectRatio) {
    case 'SQUARE':
      return { width: 600, height: 450 };
    case 'WIDESCREEN':
      return { width: 720, height: 405 };
    case 'VERTICAL':
      return { width: 405, height: 720 };
    default: return { width: 600, height: 450 };
  }
}

export const aspectRatioRercent = (aspectRatio) => {
  switch (aspectRatio) {
    case 'SQUARE':
      return '4/3';
    case 'WIDESCREEN':
      return '16/9';
    case 'VERTICAL':
      return '9/16';
    default: return '4/3';
  }
}

export const parseParamsToQueryString = (params) => {
  const q = new URLSearchParams()
  Object.keys(params).forEach((key) => {
    if (params[key] instanceof Array) {
      params[key].forEach((value) => q.append(key, value))
    } else if (params[key]) {
      q.append(key, params[key])
    }
  })
  return q
}

export const parseQueryStringToParams = (data) => {
  if(!data) return
  
  const params = new URLSearchParams(data);
  const result = {};
  params.forEach((value, key) => {
    if (key === "user") {
      result[key] = JSON.parse(decodeURIComponent(value));
    } else {
      result[key] = decodeURIComponent(value);
    }
  });

  return result
}