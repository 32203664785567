import styled from "styled-components";

export const ButtonStyled = styled.div`
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  text-transform: ${({ textTransform }) =>
    textTransform ? textTransform : 'uppercase'};
  font-size: inherit;
  display: inline-block;
  position: relative;
  text-align: center;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    img {
      opacity: 0.5;
    }
  }

  .btn-content {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 0;
    color: #fff;
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '16px')};
    font-style: normal;
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 900)};
    line-height: normal;
    text-transform: ${({ textTransform }) =>
      textTransform ? textTransform : 'uppercase'};
  }

  img {
    height: 100%;
  }

  @media (max-width: 767.98px) {
    .btn-content {
      font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '14px')};
      gap: 10px;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  @media (min-width: 768px) {
  }
`;
