import styled from 'styled-components';

export const ProfileWrapper = styled.div`
  background: #051847;
  width: 100%;
  position: relative;
  min-height: 100%;

  .dp-flex {
    display: flex;
  }

  .gap-20 {
    gap: 20px;
  }
  .align-end {
    align-items: flex-end;
  }
  .justify-start {
    justify-content: flex-start;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-between {
    justify-content: space-between;
  }

  .ant-spin-spinning {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-spin-spinning:before {
    content: '';
    background: #ececec;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.2;
  }

  .anticon {
    color: #fff;
  }

  
`;
