import { AxiosClient } from '../../../configs/axios/axios';
import { LOCAL_STORAGE_KEY } from '../../../constants';
import Storage from '../../../utils/storage';

const apiVersion = process.env.REACT_APP_VERSION;

const TelegramApi = {
  connectTelegramAccount: async (payloads) => {
    const result = await new AxiosClient().post(
      `${apiVersion}/telegram-users/link-account`,
      payloads,
      {
        headers: {
          'x-access-token': Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          'Content-Type': 'application/json',
        },
      }
    );

    return result;
  },
  withdrawMTelPoint: async (payloads) => {
    const result = await new AxiosClient().post(
      `${apiVersion}/telegram-users/withdraw-mtel-point`,
      payloads,
      {
        headers: {
          'x-access-token': Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          'Content-Type': 'application/json',
        },
      }
    );

    return result;
  },
};

export default TelegramApi;
