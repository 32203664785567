import React, { useState, useEffect, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import classNames from 'classnames';

import { Images } from '../../theme';
import {
  ChatBotStyled,
  ChatBoxContentStyled,
  ChatBoxFooterStyled,
  ChatBoxHeaderStyled,
  ChatBoxStyled,
  InputStyled,
} from './styled';

const ChatBot = () => {
  const domain = window.location.origin;
  const [messagesEnd, setMessagesEnd] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [isShowAllQuestion, setIsShowAllQuestion] = useState(false);
  const { handleSubmit, getValues, setValue, control } = useForm();
  const { fields, append } = useFieldArray({
    control,
    name: 'messages',
  });

  useEffect(() => {
    let timerId = setTimeout(() => {
      messagesEnd?.scrollIntoView({ behavior: 'smooth' });
    }, 0);
    return () => clearTimeout(timerId);
  }, [fields]);

  const questionsDefault = [
    // {
    //   id: 1,
    //   question: 'How do I log in to the game?',
    //   answer: '',
    // },
    {
      id: 2,
      question: `How to create an account and log in?`,
      answer: `To create an account and log in to Meta Casino City (MCC), follow these steps:
      \n1. Go to the [MCC Website] and click on the LET'S PLAY button.
      \n2. Select one of the following options:
      \n - Continue with SNS: Log in using your account from a supported social media platform, such as Facebook, Twitter, or Google.
      \n - Continue with wallet: Log in using your MetaMask wallet.
      \n - Continue as guest: Play the game without creating an account. However, you will not be able to make in-game purchases.
      \n3. If you chose to continue with SNS or wallet, enter your account information and follow the on-screen instructions.
      \n4. Accept the terms of service and privacy policy (if required).
      \n5. Once you have registered an account, you will be able to enter the MCC metaverse and explore the world, interact with other players, and participate in activities and events.
      
      \nAdditional tips:
      \n - If you're login with an email, choose a strong password for your account and do not share it with anyone.
      \n - Keep your account information private.
      \n - If you are using a mobile device, make sure to keep the MetaMask app updated to the latest version.
      \n - If you have any problems registering an account or logging in, contact MCC support for help.`
    },
    {
      id: 3,
      question: `How to play the game?`,
      answer: `Meta Casino City (MCC) is a virtual world where users can explore, interact with each other, and participate in activities and events.
      \n To play the game, you will need to register an account and log in. Once you are logged in, you will be able to enter the MCC metaverse.
      
      \nHere is a basic overview of how to play the game:
      \n1. Move around. You can move around MCC using the WASD keys on your keyboard or by clicking and dragging with your mouse.
      \n - Using the WASD keys: Press the W key to move forward, the A key to move left, the S key to move backward, and the D key to move right.
      \n - Using the mouse: Click and hold the left mouse button, then drag the mouse in the direction you want to move.
      \n - Using the Space key: Press the Space key to jump. To jump in a specific direction, press the Space key and the corresponding WASD key. Hold down the Space key longer to jump repeatedly.
      \n2. Interact with objects. To interact with objects in the game, move your avatar close to them and press the F key.
      \nThis will open a menu where you can perform various actions, such as picking up objects, opening doors, or talking to NPCs. To close the menu, press the Esc key.
      \n3. Participate in activities. There are many different activities to participate in in MCC, such as playing games, attending events, or visiting casinos. To find activities, you can browse the map or use the search bar.
      \n4. Interact with other players. MCC is a social world, so you can interact with other players by talking to them, sending them messages, or playing games together.
      
      \nHere are some additional tips for playing MCC:
      \n - You can use the map to navigate MCC and find places of interest.
      \n - You can use the search bar to find specific activities, events, or players.
      \n - You can use the chat feature to communicate with other players.
      \n - You can use the inventory system to store and manage your items or change your avatar's appearance.
      
      \nMCC is a complex game with many different features, so it is impossible to cover everything in a short guide. However, the above tips should give you a good starting point.`
    },
    {
      id: 4,
      question: `How to make money in the game?`,
      answer: `There are a number of ways to make money in Meta Casino City (MCC). Here are a few examples:
      \n - Sell NFTs. You can create and sell your own NFTs in MCC. This could include things like digital art, wearables, or even virtual land.
      \n - Play games. There are many different games in MCC that offer rewards in the form of POINT. Some of these games are skill-based, while others are more like gambling games.
      \n - Provide services. You can offer services to other MCC users, such as building worlds, designing avatars, or creating experiences. You can charge for your services in POINT.
      \n - Invest in land. Land in MCC is a valuable asset, and you can make money by buying and selling it. You can also lease your land to other users, or rent it out for events.
      
      \nHere are some additional tips for making money in MCC:
      \n - Do your research. Before you invest any money in MCC, it is important to do your research and understand the risks involved.
      \n - Start small. Don't invest more money than you can afford to lose.
      \n - Be patient. It takes time to build a successful business in MCC. Don't expect to get rich quick.`
    },
    {
      id: 5,
      question: `How to interact with other players?`,
      answer: `There are a number of ways to interact with other players in Meta Casino City (MCC). Here are a few examples:
      \n - Chat. You can chat with other players using the chat feature. To do this, press Enter on your keyboard to open the chat window. You can then type your message and press Enter again to send it.
      \n   + Normal chat: This is the most common type of chat and is used to communicate with other players who are nearby.
      \n     To send a normal chat message, simply press Enter on your keyboard to open the chat window, type your message, and press Enter again to send it.
      \n   + Group chat: This type of chat is used to communicate with a specific group of players.
      \n     To create a group chat, open the chat window and click on the ""Group chat"" tab. You can then add players to the group and start chatting.
      \n   + World chat: This type of chat is used to communicate with all players in the current world.
      \n     To send a world chat message, open the chat window click on the ""World chat"" tab, type your message, and press Enter again to send it.
      \n - Voice chat. You can also voice chat with other players using a microphone. To do this, click on the microphone icon in the chat window.
      \n - Emotes. You can use emotes to express yourself to other players. To do this, press the I key on your keyboard to open the emote menu. You can then select an emote and click on it to perform it.
      \n - Gestures. You can also use gestures to interact with other players. For example, you can wave, clap, or dance. To do this, press the G key on your keyboard to open the gesture menu. You can then select a gesture and click on it to perform it.
      \n - Activities. You can participate in activities with other players, such as playing games, attending events, or exploring worlds together. To find activities, you can browse the map or use the search bar.
      
      \nHere are some additional tips for interacting with other players in MCC:
      \n - Be respectful. Treat other players the way you want to be treated.
      \n - Be helpful. If you see someone who needs help, offer to assist them.
      \n - Be aware of your surroundings. Be careful not to bump into other players or run into objects.
      \nHave fun! MCC is a social world, so enjoy interacting with other players.
      
      \nHere are some specific examples of how you can interact with other players in MCC:
      \n - Join a club or community. There are many different clubs and communities in MCC. Joining a club or community is a great way to meet other players who share your interests.
      \n - Attend events. There are many different events happening in MCC all the time. Attending events is a great way to meet new people and have fun.
      \n - Play games with other players. There are many different games in MCC that you can play with other players. Playing games is a great way to bond with other players and make new friends.`
    },
    {
      id: 6,
      question: `How to use the features in the game?`,
      answer: `Meta Casino City (MCC) is a virtual world where users can explore, interact with each other, and participate in activities and events. 
      \nTo use the features in MCC, you will need to create an account and log in. Once you are logged in, you will be able to enter the MCC metaverse.
      
      \nHere is a brief overview of some of the key features in MCC:
      \n - Movement: You can move around MCC using the WASD keys on your keyboard or by clicking and dragging with your mouse. You can also use the Space bar to jump.
      \n - Interaction: You can interact with objects and other players in MCC by clicking on them. This will open a menu where you can perform various actions, such as picking up objects, opening doors, or talking to NPCs.
      \n - Chat: You can chat with other players in MCC using the chat feature. To do this, press Enter on your keyboard to open the chat window. You can then type your message and press Enter again to send it.
      \n - Inventory: You can use the inventory system to store and manage your avatars, items,... To open the inventory, press the I key on your keyboard.
      
      \nIn addition to these basic features, there are a number of other features in MCC that you can use, such as:
      \n - The map: The map can be used to navigate MCC and find places of interest. To open the map, press the M key on your keyboard.
      \n - The marketplace: The marketplace can be used to buy and sell NFTs, such as digital art, wearables, and virtual land. To open the marketplace, press the P key on your keyboard.
      \n - The casino games are located at Casino Tower, which offers a variety of activities to attend to.`
    },
    {
      id: 7,
      question: `How to participate in the activities in the game?`,
      answer: `Here are some tips on how to participate in the activities in Meta Casino City (MCC):
      \n - Use the map to find activities. The map can be used to find activities, events, and places of interest. To open the map, press the M key on your keyboard.
      \n - Use the search bar to find specific activities. The search bar can be used to find specific activities, events, or players. To open the search bar, press the / key on your keyboard.
      \n - Join clubs and communities. Clubs and communities are a great way to find and participate in activities. To find clubs and communities, open the map and click on the ""Community"" tab.
      \n - Attend events. There are many different events happening in MCC all the time. To find events, open the map and click on the ""Events"" tab.
      \n - Play games. There are many different games to play in MCC. To find games, open the map and click on the ""Games"" tab.
      
      \nHere are some specific examples of activities that you can participate in in MCC:
      \n - Visit the MCC BET. The MCC BET is a popular place to play casino games and gamble.
      \n - Attend a concert or event at one of the many venues in MCC. There are many different concerts and events happening in MCC all the time.
      \n - Start a business or organization in MCC. MCC is a virtual economy, so you can start a business or organization and earn money.
      
      \nHere are some additional tips for participating in the activities in MCC:
      \n - Be respectful. Treat other players the way you want to be treated.
      \n - Be helpful. If you see someone who needs help, offer to assist them.
      \n - Be aware of your surroundings. Be careful not to bump into other players or run into objects.
      
      \nHave fun! MCC is a social world, so enjoy participating in activities with other players.`
    },
    {
      id: 8,
      question: `How to buy and sell in-game items?`,
      answer: `To buy and sell in-game items in Meta Casino City (MCC), you will need to use the MCC Marketplace. The Marketplace is a decentralized platform where users can buy, sell, and trade in-game items.
      \nTo access Marketplace, using hotkey X or click on Marketplace icon in game's menu.
      \nTo buy an item on the Marketplace, find the item you want to buy and click on the ""Cart"" icon. You will then be prompted to enter the amount of POINT (MCC's cryptocurrency) that you want to pay for the item. Once you have entered the amount, click on the ""Confirm"" button to complete the purchase.
      \nTo sell an item on the Marketplace, find the item you want to sell in your inventory and click on the ""Sell"" icon. You will then be prompted to enter the amount of POINT that you want to sell the item for. Once you have entered the amount, click on the ""Confirm"" button to post your item for sale.
      
      \nHere are some additional tips for buying and selling in-game items in MCC:
      \n - Be careful of scams. There are always scammers trying to take advantage of people in the crypto world. Be sure to do your research before buying or selling anything.
      \n - Use a trusted wallet. When buying or selling in-game items, it is important to use a trusted wallet. This will help to ensure that your transactions are safe and secure.
      \n - Be patient. It can take some time to find the right buyer or seller for an item. Be patient and don't be afraid to negotiate.
      
      \nHere are some specific examples of in-game items that you can buy and sell in MCC:
      \n - Land
      \n - Wearables
      \n - Avatars
      \n - Tickets
      \n - Badges
      \n - Game items (e.g., furnitures, vehicles, etc.)
      
      \nYou can also buy and sell NFTs in MCC. NFTs are non-fungible tokens, which means that they are unique and cannot be replaced. NFTs can be used to represent ownership of digital assets, such as in-game items, artwork, and music.
      \nTo buy and sell NFTs in MCC, you will need to use a compatible NFT marketplace. There are a number of different NFT marketplaces available, such as OpenSea and Rarible.
      \nOnce you have found an NFT marketplace that you want to use, simply create an account and connect your wallet. You can then start browsing and bidding on NFTs.
      \nIf you have an NFT that you want to sell, you can list it for sale on the marketplace. Once you have listed your NFT for sale, other users will be able to bid on it. If someone bids on your NFT and you accept the bid, the NFT will be transferred to the buyer and you will receive the payment in your wallet.
      
      \nBuying and selling in-game items in MCC can be a great way to earn money or to get the items that you want. However, it is important to be careful and to do your research before buying or selling anything.`
    },
    {
      id: 9,
      question: `How to get technical support?`,
      answer: `Here are some ways to get technical support in Meta Casino City (MCC):
      \n - Use the MCC Discord server. The MCC Discord server is a great place to get help from other users and from the MCC team. You can join the Discord server here: https://mcc.xxx/discord
      \n - Contact MCC support. You can contact MCC support by submitting a ticket. To submit a ticket, go to the MCC support website: https://mcc.xxx/support/
      \n - Look for tutorials and help articles online. There are a number of tutorials and help articles available online that can help you with technical issues in MCC. You can find these tutorials and help articles by searching online.
      
      \nHere are some additional tips for getting technical support in MCC:
      \n - Be specific when describing your problem. The more specific you can be, the easier it will be for the person helping you to troubleshoot the problem.
      \n - Provide screenshots or videos if possible. Screenshots and videos can be very helpful in diagnosing and resolving technical problems.
      \n - Be patient. It may take some time for the person helping you to resolve your problem.
      
      \nI hope this helps!`
    },
    {
      id: 10,
      question: `How to buy and sell NFT?`,
      answer: `To buy and sell NFTs in Meta Casino City (MCC), you will need to use the MCC NFT marketplace.
      \nYou will need to create an account and connect your MetaMask wallet.
      \nOnce you have connected your wallet, you can start browsing and bidding on NFTs.
      
      \nTo buy an NFT, simply find the NFT that you want to buy and click on the ""Cart"" icon. You will then be prompted to enter the amount of cryptocurrency that you want to pay for the NFT.
      \nOnce you have entered the amount, click on the ""Confirm"" button to complete the purchase.
      
      \nTo sell an NFT, simply find the NFT that you want to sell and click on the ""Sell"" button. You will then be prompted to enter the amount of cryptocurrency that you want to sell the NFT for.
      \nOnce you have entered the amount, click on the ""Confirm"" button to list your NFT for sale.
      
      \nIf someone bids on your NFT and you accept the bid, the NFT will be transferred to the buyer and you will receive the payment in your wallet.
      
      \nHere are some additional tips for buying and selling NFTs in MCC:
      \n - Do your research. Before buying or selling an NFT, it is important to do your research. This includes researching the artist or creator of the NFT, as well as the NFT marketplace that you are using.
      \n - Be aware of the fees. NFT marketplaces typically charge fees for buying and selling NFTs. Be sure to check the fees before you buy or sell an NFT.
      \n - Be patient. It may take some time to find the right buyer or seller for an NFT. Be patient and don't be afraid to negotiate.
      
      \nHere are some specific examples of NFTs that you can buy and sell in MCC:
      \n - Land
      \n - Wearables
      \n - Avatars
      \n - Tickets
      \n - Badges
      \n - Game items (e.g., furnitures, vehicles, etc.)
      \n - Artwork
      \n - Music
      \nBuying and selling NFTs in MCC can be a great way to earn money or to get the items that you want. However, it is important to be careful and to do your research before buying or selling anything.`
    },
    {
      id: 11,
      question: `How to play Wikibet?`,
      answer: `Wikibet is a decentralized gambling platform that allows users to bet on a variety of events, including sports, esports, and casino games.
      \nTo play Wikibet, you will need to create an account and deposit cryptocurrency into your account.
      
      \nOnce you have deposited cryptocurrency, you can start betting on events. To place a bet, simply find the event that you want to bet on and click on the ""Bet"" button.
      \nYou will then be prompted to enter the amount of cryptocurrency that you want to bet and the odds that you want to bet on. Once you have entered the amount and odds, click on the ""Confirm"" button to place your bet.
      \nIf your bet wins, you will receive the winnings in your Wikibet account. If your bet loses, you will lose the cryptocurrency that you bet.
      
      \nHere are some additional tips for playing Wikibet:
      \n - Do your research. Before betting on any event, it is important to do your research and understand the risks involved.
      \n - Start small. When you are first starting out, it is best to start with small bets. This will help you to minimize your losses if your bets do not win.
      \n - Use a strategy. There are a number of different betting strategies that you can use. It is important to find a strategy that works for you and to stick to it.
      \n - Be patient. It takes time to become a successful gambler. Don't expect to get rich quick.
      
      \nHere are some specific examples of events that you can bet on in Wikibet:
      \n - Sports events (e.g., soccer, basketball, baseball, etc.)
      \n - Esports events (e.g., League of Legends, Dota 2, CS:GO, etc.)
      \n - Casino games (e.g., roulette, blackjack, slots, etc.)
      \n - Playing Wikibet can be a fun and exciting way to gamble. However, it is important to be careful and to gamble responsibly.`
    },
  ];

  const onSubmit = (data) => {
    if (data?.message) {
      append({
        question: data?.message,
        answer: `Thank you for contacting support. We are currently experiencing a high volume of inquiries, so we appreciate your patience as we work through them. We will respond to your inquiry as soon as possible.
        \nIn the meantime, you may be able to find the answer to your question in our FAQ section on our website: \n${domain}
        \nThank you for your understanding.`
      });
      setValue('message', '');
    }
  };

  const selectQuestion = (question) => {
    append({
      question: question?.question,
      answer: question?.answer || question?.question,
    });
  };

  const renderListQuestion = (limit = 5) => {
    return isShowAllQuestion
      ? questionsDefault?.map(renderQuestionItem)
      : questionsDefault?.slice(0, limit)?.map(renderQuestionItem)
  }

  const renderQuestionItem = (question) => {
    return (
      <li
        key={question?.id}
        className={classNames({ active: activeQuestion === question?.id })}
        onClick={() => {
          selectQuestion(question)
          setActiveQuestion(question?.id)
        }}
      >
        {question?.question}
      </li>
    )
  }

  return (
    <>
      <ChatBotStyled
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      ></ChatBotStyled>
      {isOpen &&
        createPortal(
          <ChatBoxStyled>
            {/* <ChatBoxHeaderStyled>Chat box</ChatBoxHeaderStyled>
            <ChatBoxContentStyled>
              <div className='our-messages'>
                Hello, what do you need help with?
              </div>
              <div className='our-messages-box'>
                <ul>
                  {renderListQuestion()}

                  <li key='view-more' className='view-more' onClick={() => setIsShowAllQuestion(!isShowAllQuestion)}>
                    {isShowAllQuestion ? 'Hide questions' : 'View more questions'}
                  </li>
                </ul>
              </div>
              {fields?.map((message, index) => (
                <Fragment key={index}>
                  {message?.question && (
                    <div key={message?.id} className='your-messages'>
                      {message?.question}
                    </div>
                  )}
                  <div key={`answer-${message?.id}`} className='answer'>
                    {message?.answer}
                  </div>
                </Fragment>
              ))}
              <div
                style={{ float: 'left', clear: 'both' }}
                ref={(el) => setMessagesEnd(el)}
              ></div>
            </ChatBoxContentStyled>
            <ChatBoxFooterStyled>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name='message'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputStyled
                      placeholder='Message'
                      id='message'
                      type='text'
                      onChange={onChange}
                      value={value || ''}
                      name='message'
                      autoComplete="off"
                      className="input-chatbox"
                    />
                  )}
                />
                <img
                  className='btn-send'
                  src={Images.ICON_SEND}
                  alt='Send message'
                  onClick={() => onSubmit(getValues())}
                />
              </form>
            </ChatBoxFooterStyled> */}
            <iframe src="https://mga-web-ai.metacasinocity.io/" style={{ border: "none", borderRadius: "6px" }} height={700} title="Iframe AI Chat" id='chat-iframe'></iframe>
            
          </ChatBoxStyled>,
          document.body
        )}
    </>
  );
};

export default ChatBot;
