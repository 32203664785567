import { createAsyncThunk } from '@reduxjs/toolkit';
import TelegramApi from './telegram.api';

export const connectTelegramAccountAction = createAsyncThunk(
  'ConnectTelegramAccount',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await TelegramApi.connectTelegramAccount(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const withdrawMTelPointAction = createAsyncThunk(
  'WithdrawMTelPointAction',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await TelegramApi.withdrawMTelPoint(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
)