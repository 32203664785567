import { useNavigate } from "react-router";
import { notification } from "antd";

import {
  refreshTokenAction,
  retrieveAccountAction,
} from "../stores/screens/auth/auth.action";
import * as api from "../api";
import { useAppDispatch } from "../stores";
import LocalStorage from "../utils/storage";
import { useWeb3Auth } from "../contexts/web3auth";
import Confirmable from "../components/confirmable";
import useSSOLogin from "./ssoLogin";

const useConnectWallet = (accessToken) => {
  const navigate = useNavigate();
  const { handleLogout } = useWeb3Auth();
  const { auth_token, public_address } = useSSOLogin();
  const dispatch = useAppDispatch();

  const isLogin = !!LocalStorage.get("ACCESS_TOKEN");

  const handleSubmitCrossSite = async ({ accounts, identifier }) => {
    try {
      const { code, data } = await api.loginSSOWithWallet({
        auth_token: accounts,
        identifier: identifier,
      });
      const { access_token, user_id, expired_at, refresh_token } = data;
      if (code === 200) {
        window.open(
          `${process.env.REACT_APP_SITE_WALLET_URL}?authToken=${access_token}&userId=${user_id}&publicAddress=${identifier}&expiredToken=${expired_at}&refreshToken=${refresh_token}`,
          "_blank"
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        try {
          const { payload } = await dispatch(
            refreshTokenAction({
              auth_token: accounts,
              identifier: identifier,
            })
          );
          if (payload?.data && payload.data?.access_token) {
            return handleSubmitCrossSite({
              accounts: payload?.data?.access_token,
              identifier,
            });
          }
        } catch (error) {
          if (error?.response?.status === 401) {
            handleLogout();
            navigate("/login");
          } else {
            notification.error({
              message: error?.response?.data?.message,
              duration: 5,
            });
          }
        }
      } else if (
        error?.response?.data?.error ===
        "ERR_USER_BLOCKED_BY_WRONG_PASSWORD_TRANSACTION"
      ) {
        Confirmable.open({
          content:
            "Your wallet has been locked due to an incorrect transaction password.",
          hideCancelButton: true,
          isShowIconExclamation: true,
          hideIconClose: true,
          acceptButtonText: "Contact Us",
          onOk: () =>
            window.open(
              `${process.env.REACT_APP_SITE_WALLET_URL}/contact-us`,
              "_blank"
            ),
        });
      } else {
        notification.error({
          message: error?.response?.data?.message,
          duration: 5,
        });
      }
    }
  };

  const handleSSO = async () => {
    if (isLogin) {
      const { payload } = await dispatch(
        retrieveAccountAction(accessToken || auth_token)
      );

      if (payload?.status) {
        handleSubmitCrossSite({
          accounts: payload?.data.accounts[0].auth_token,
          identifier: payload?.data.accounts[0].identifier,
        });
      }
    } else {
      window.open(process.env.REACT_APP_SITE_WALLET_URL, "_blank");
    }
  };

  return {
    auth_token,
    public_address,
    handleSSO,
    navigate,
    handleLogout,
  };
};

export default useConnectWallet;
