import { createSlice } from '@reduxjs/toolkit';
import {
  connectTelegramAccountAction,
  withdrawMTelPointAction
} from './telegram.action';

const initialState = {
  isLoading: false,
  error: {},
  userProfile: {},
  accountConnected: false
};

const { actions, reducer } = createSlice({
  name: 'telegram_slice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(connectTelegramAccountAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(connectTelegramAccountAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.accountConnected = true;
      })
      .addCase(connectTelegramAccountAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(withdrawMTelPointAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(withdrawMTelPointAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(withdrawMTelPointAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export { reducer };
export default actions;
